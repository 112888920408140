import React from 'react';
import { Modal, Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const LoadingContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
});

const Text = styled(Typography)({
  color: 'black',
});

const CenteredCircularProgress = styled(CircularProgress)({
  padding: 'auto',
});

const LoadingScreen = ({ open }) => {
  return (
    <Modal open={open}>
      <LoadingContainer>
        <Card>
          <CardContent>
            <CenteredCircularProgress color="primary"/>
              <Text variant="h6">Cargando...</Text>
          </CardContent>
        </Card>
      </LoadingContainer>
    </Modal>
  );
};

export default LoadingScreen;
