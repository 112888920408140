import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { Sesion } from '@/interfaces/inter-sesion';
import { PublicClientApplication, AuthenticationResult, RedirectRequest, AccountInfo } from '@azure/msal-browser';
import { msalConfig } from '../../msal/authConfig';


export interface ApiResponse {
  data: Sesion[];
  status: string;
}

export interface SliceState {
  data: Sesion[] | []; // Asegúrate de que data pueda ser nulo inicialmente
  loading: boolean;
  error: string | null;
}

const initialState: SliceState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchData = createAsyncThunk<Sesion[],AccountInfo[]>('sesion/fetchData', async (payload, thunkAPI) => {
  try {
    const { username } = payload[0];

    const token = process.env.REACT_APP_TOKEN; // Reemplaza con tu token de autenticación
    const response = await axios.get(`https://ws.ugm.cl/api/v1_presupuesto/sesion?username=${username}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    console.log('response.data sesion', response.data.data);
    return response.data.data;

  } catch (error) {
    throw new Error('Error al cargar los datos');
  }
});

const sesionSlice = createSlice({
  name: 'sesion',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchData.fulfilled, (state, action: PayloadAction<Sesion[]>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchData.rejected, (state, action: PayloadAction<any, string, any, any>) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default sesionSlice;
