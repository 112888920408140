import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Menu,
  MenuItem,
  Card
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { NavbarProps } from '@/interfaces/inter-navbar';

// Constantes para los estilos CSS
const appBarStyles: React.CSSProperties = {
  top: 0,
  width: '100%',
  zIndex: 999,
  backgroundColor: 'rgba(0, 100, 0, 0.5)',
  position: 'fixed', // Añade la propiedad position y establece su valor en "fixed"
};

const appBarContentStyles: React.CSSProperties = {
  flexGrow: 1,
};

const drawerStyles: React.CSSProperties = {
  top: 0,
  minWidth: '240px',
  zIndex: 1000,
  background: 'rgba(0, 0, 0, 0.1)', // Cambia el valor del canal alfa según sea necesario
  backgroundColor: 'transparent',
};

const logoContainerStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  padding: '16px',
};

const Navbar: React.FC<NavbarProps> = ({ title, menuItems, childNode }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const logoUrl = '/img/logo UGM-n.svg';

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <AppBar position="static" style={appBarStyles}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={appBarContentStyles}>
            {title}
          </Typography>
          <IconButton color="inherit" onClick={handleAvatarClick}>
            <AccountCircleIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={openDrawer} onClose={handleDrawerClose} style={drawerStyles}>

        <div className="logoContainer" style={logoContainerStyles}>
          <img src={logoUrl} alt="Logo" className="logoImage" />
        </div>

{childNode}
    
      </Drawer>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleMenuClose}>Cerrar sesión</MenuItem>
      </Menu>
    </div>
  );
};

export default Navbar;
