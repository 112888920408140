import React from 'react';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Card, CardContent } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { EstadoFinanciero } from '../../interfaces/inter-contabilidad';
import { EstadoFinancieroDetalle } from '@/interfaces/inter-contabilidad-cuentas';
import { useParams } from 'react-router-dom';
import ModalDetalleContabilidad from '../modal/modal-detalle-contabilidad';

const currentYear = new Date().getFullYear();

const getParameterNode = (nombreParametro) => {

  switch (nombreParametro) {
    case 'UNIDAD_PADRE_CODIGO':
      return 'NIVEL_1';

    case 'PLCC_CODIGO':
      return 'NIVEL_2';

    case 'CECO_CODIGO':
      return 'NIVEL_3';
  }

};

// Configuraciones de estilo
const headerCardStyles = {
  background: 'linear-gradient(to right, #192A56, #0097E6)',
  color: 'white',
};

const subHeaderCardStyles = {
  background: 'linear-gradient(to right, #FF0000, #FF9999)',
  color: 'white',
};

const cardContentStyles = {
  background: 'rgba(255, 255, 255, 0.2)',
};


// Función de renderizado de encabezado
const renderHeader = (header) => (
  <Grid container xs={12} spacing={2}>
    <Grid item xs={6}>
      <Card variant="outlined" sx={headerCardStyles}>
        <CardContent>
          <Typography variant="subtitle1">{header.ESFI_DESCRIPCION}</Typography>
        </CardContent>
      </Card>
    </Grid>
    {renderHeaderColumn(`${currentYear} Ppto.`)}
    {renderHeaderColumn(`${currentYear} a la Fecha`)}
    {renderHeaderColumn('% Cumplimiento')}
  </Grid>
);

// Función de renderizado de descripción
const renderDescript = (column, parameterName, id?, parametro?) => (
  <Grid item xs={6} key={column[parameterName]}>
    <Card variant="outlined">
      <CardContent>
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{column[parameterName]}</Typography>
          </Grid>
          <Grid item xs={4}>
            {parameterName === 'COFI_DESCRIPCION' && (
              <ModalDetalleContabilidad
                parametro={column['COFI_CODIGO']}
                nombreParametro={'COFI_CODIGO'}
                parametro2={id}
                nombreParametro2={parametro}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);

// Función de renderizado de columna de encabezado
const renderHeaderColumn = (column) => (
  <Grid item xs={2}>
    <Card variant="outlined" sx={subHeaderCardStyles}>
      <CardContent>
        <Typography variant="subtitle2">{column}</Typography>
      </CardContent>
    </Card>
  </Grid>
);

// Función de cálculo del parámetro total
const totalParameter = (data, parameter, parameterName, column) => {
  const dataFilter = data.filter(value => value[parameterName] === parameter);
  const sum = dataFilter.reduce((total, value) => {
    const fieldValue = parseFloat(value[column]);
    if (!isNaN(fieldValue)) {
      return total + fieldValue;
    }
    return total;
  }, 0);
  return sum;
};

// Función de renderizado del total
const renderTotal = (data, parameter, parameterName, column) => {
  const formattedSum = totalParameter(data, parameter, parameterName, column).toLocaleString().replace(/,/g, '.');
  return (
    <Grid item xs={2} sm={2} md={2} key={parameter}>
      <Card variant="outlined" sx={{ height: '100%', width: '100%' }}>
        <Grid container alignItems="center" sx={{ height: '100%', width: '100%' }}>
          <Grid item xs={12}>
            <CardContent>
              <Typography variant="subtitle2" sx={{ textAlign: 'right' }} >{formattedSum}</Typography>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

// Función de renderizado del porcentaje
const renderPorcentaje = (data, parameter, parameterName, column, column2) => {
  const value1 = totalParameter(data, parameter, parameterName, column);
  const value2 = totalParameter(data, parameter, parameterName, column2);
  const porcentaje = (value2 * 100) / value1;
  const result = !isNaN(porcentaje) ? porcentaje.toFixed(2) : 0;
  return (
    <Grid item xs={2} sm={2} md={2} key={parameter}>
      <Card variant="outlined" sx={{ height: '100%', width: '100%' }}>
        <Grid container alignItems="center" sx={{ height: '100%', width: '100%' }}>
          <Grid item xs={12}>
            <CardContent>
              <Typography variant="subtitle2" sx={{ textAlign: 'right' }} >{result}%</Typography>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

const Formulario = () => {

  const { id, parametro } = useParams();

  const categorias: EstadoFinanciero[] = useSelector((state: RootState) => state.estadosFinancieros.data);
  const detalle = useSelector((state: RootState) => state.estadosFinancierosDetalle.data);
  const filterDetalle = (id === "0") ? detalle : detalle.filter((value: EstadoFinancieroDetalle) => value[parametro] === id);

  console.log('filterDetalle',filterDetalle)

  /**
   * Validacion de parametros
   */
  const sesion = useSelector((state: RootState) => state.sesion.data);

  const validateParam = (param: string, value: number) => {
    return null == sesion[0][getParameterNode(param)] || value == sesion[0][getParameterNode(param)];
  };



  return !validateParam(parametro, parseInt(id)) ?
    <></>
    : (
      <Grid container spacing={2} style={{ marginTop: '64px' }}>
        {categorias.map((value) => (
          <>
            <Grid item xs={12} key={value.ESFI_CODIGO}>
              {renderHeader(value)}
            </Grid>

            {value.TIPO.map((tipoValue, index) => (
              <Grid item spacing={2} xs={12} key={tipoValue.INFI_CODIGO}>
                <Accordion>
                  <AccordionSummary>
                    <Grid container spacing={2} xs={12} key={tipoValue.INFI_CODIGO}>
                      {renderDescript(tipoValue, 'INFI_DESCRIPCION')}
                      {renderTotal(filterDetalle, tipoValue.INFI_CODIGO, 'INFI_CODIGO', 'PRAP_TOTAL_SOLICITADO')}
                      {renderTotal(filterDetalle, tipoValue.INFI_CODIGO, 'INFI_CODIGO', 'EJECUTADO_FECHA')}
                      {renderPorcentaje(filterDetalle, tipoValue.INFI_CODIGO, 'INFI_CODIGO', 'PRAP_TOTAL_SOLICITADO', 'EJECUTADO_FECHA')}
                    </Grid>
                  </AccordionSummary>

                  <AccordionDetails style={cardContentStyles}>
                    <Card variant="outlined" sx={cardContentStyles}>
                      {tipoValue.SUBTIPO.map((subTipoValue, subIndex) => (
                        <Grid container spacing={2} xs={12} key={subTipoValue.COFI_CODIGO}>
                          {renderDescript(subTipoValue, 'COFI_DESCRIPCION', id, parametro)}
                          {renderTotal(filterDetalle, subTipoValue.COFI_CODIGO, 'COFI_CODIGO', 'PRAP_TOTAL_SOLICITADO')}
                          {renderTotal(filterDetalle, subTipoValue.COFI_CODIGO, 'COFI_CODIGO', 'EJECUTADO_FECHA')}
                          {renderPorcentaje(filterDetalle, subTipoValue.COFI_CODIGO, 'COFI_CODIGO', 'PRAP_TOTAL_SOLICITADO', 'EJECUTADO_FECHA')}
                        </Grid>
                      ))}
                    </Card>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </>
        ))}
      </Grid>
    );
};

export default Formulario;
