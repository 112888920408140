import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './msal/authProvider';
import { MsalProvider, UnauthenticatedTemplate, AuthenticatedTemplate } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { Provider, useSelector, useDispatch } from 'react-redux';
import store, { AppDispatch } from './store';
import PrivateRoute from './component/route/private-route';
import LoginMS from './component/login-microsoft';
import { msalConfig } from './msal/authConfig';
import { fetchData as centroFetch } from 'store/centroCosto/sliceCentro';
import { fetchData as centroCostoDetalle } from 'store/centroCostoDetalle/sliceCentroCostoDetalle';
import { fetchData as contabilidadFetch } from 'store/contabilidad/sliceContabilidad';
import { fetchData as contDetalleFetch } from 'store/contabilidadDetalle/sliceContabilidadDetalle';
import { fetchData as sesionFetch } from 'store/sesion/sliceSesion';

import Loading from './component/utils/loading-screen'

const App: React.FC = () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  const accounts = msalInstance.getAllAccounts();

  const dispatch = useDispatch<AppDispatch>();

  const [load, setLoad] = useState(true)


  const asyncFunction = async () => {
    // Realiza cualquier acción inicial necesaria aquí
    const data = await Promise.all([
      dispatch(sesionFetch(accounts)),
      dispatch(centroFetch()),
      dispatch(centroCostoDetalle()),
      dispatch(contabilidadFetch()),
      dispatch(contDetalleFetch())
    ])
      .catch(error => {
        // Manejar errores si ocurre alguno al ejecutar los dispatch
        console.error('Error en la carga de datos:', error);

      })

    data && setLoad(false)

  }


  useEffect(() => {

    asyncFunction()

  }, []);

  return load ?

    <Loading open={true} />

    : (
      <MsalProvider instance={msalInstance}>
        <Router>
          <AuthProvider>
            <AuthenticatedTemplate>
              <PrivateRoute />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <LoginMS />
            </UnauthenticatedTemplate>
          </AuthProvider>
        </Router>
      </MsalProvider>
    );


};

export default App;
