import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { styled } from '@mui/system';
import { CSVLink } from 'react-csv';


import {
  Button,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  useMediaQuery,
  colors,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { cuentasPorCentro } from '@/interfaces/inter-cuentas-centro';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ parametro, nombreParametro, parametro2, nombreParametro2 }: { parametro: string, nombreParametro: keyof cuentasPorCentro, parametro2: string, nombreParametro2: string }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  console.log('parametro', parametro,nombreParametro)
  console.log('parametro2', parametro2,nombreParametro2)

  const colors = [
    '#D2D2C0', '#F1F1EC', //color tabla general
    '#88FA3E', '#DBFDC5', //valor solicitado
    '#cc0000', '#daa8a8', //valor ajustado
    '#91A6FF', '#C8D2FF', //colores tabla mes por año
  ]

  const row = useSelector((state: RootState) => state.centroCostoDetalle.data);
  console.log('row', row)

  const conditionFilter = () => {
//    return (nombreParametro2 && nombreParametro2 in ['UNIDAD_PADRE_CODIGO', 'PLCC_CODIGO', 'CECO_CODIGO'])
    return ( (parametro2 != "0") )
      ? row.filter(
        (value: cuentasPorCentro) =>
          value[nombreParametro] == parametro && value[nombreParametro2] == parametro2
      )
      : row.filter((value: cuentasPorCentro) => value[nombreParametro] == parametro)
  };

  const filterRow = conditionFilter();
  console.log('conditionFilter', filterRow)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: '##AEB6BF',
    color: 'black',
    fontSize: '12px',
    fontFamily: 'serif',
    border: '1px solid black', // Add border style
    padding: '8px', // Add padding
  }));


  const StyledTableHead = styled(TableRow)(({ theme }) => ({
    color: 'black',
    fontSize: '12px',
    fontFamily: 'serif',
    fontWeight: 'bold',
    border: '1px solid #000',
  }));

  // ALINEAR CONTENIDO DE TABLA A LA DERECHA (NUMEROS) AMBPLIAR TITULOS UNA SOLA LINEA
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen} fullWidth >
        Detalle
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}

      >
        <AppBar sx={{ position: 'relative', zIndex: 9999 }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>


            <div style={{ marginLeft: 'auto' }}>
              <CSVLink data={filterRow} filename="data.csv" separator=";">
                <Button color="inherit" sx={{ color: 'white' }}>Exportar a CSV</Button>
              </CSVLink>
            </div>

          </Toolbar>
        </AppBar>

        <DialogContent>
          <Table>
            {filterRow && filterRow.length > 0 && (

              <StyledTableHead>
                <StyledTableCell style={{ backgroundColor: colors[1] }}>{'Cuenta'}</StyledTableCell>
                <StyledTableCell style={{ backgroundColor: colors[1] }}>{'Nombre Cuenta'}</StyledTableCell>
                <StyledTableCell style={{ backgroundColor: colors[1] }}>{'Centro de Costo'}</StyledTableCell>
                <StyledTableCell style={{ backgroundColor: colors[1] }}>{'Descripción Detalle'}</StyledTableCell>
                <StyledTableCell style={{ backgroundColor: colors[1] }}>{'Monto'}</StyledTableCell>
              </StyledTableHead>

            )}

            {filterRow &&
              filterRow.map((value: cuentasPorCentro) => (

                <StyledTableHead key={value.CUCO_CUENTA}>
                  <StyledTableCell style={{ backgroundColor: colors[1] }}>{value.CUCO_CUENTA}</StyledTableCell>
                  <StyledTableCell style={{ backgroundColor: colors[1] }}>{value.CUCO_NOMBRE_CUENTA}</StyledTableCell>
                  <StyledTableCell style={{ backgroundColor: colors[1] }}>{value.CECO_NOMBRE}</StyledTableCell>
                  <StyledTableCell style={{ backgroundColor: colors[1] }}>{value.PRAP_DESCRIPCION}</StyledTableCell>
                  <StyledTableCell style={{ backgroundColor: colors[1] }}>{value.PRAP_TOTAL_FINAL}</StyledTableCell>
                </StyledTableHead>

              ))}
          </Table>
        </DialogContent>

      </Dialog>
    </div >
  );
}

