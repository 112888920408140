import React from 'react';
import TreeNode from './TreeNode';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { PLCCData } from '../../interfaces/inter-ccosto';
import './tree.css'; // Importa el archivo CSS
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Sesion } from '@/interfaces/inter-sesion';

const Tree: React.FC = () => {
  const rows: PLCCData[] = useSelector((state: RootState) => state.centroCosto.data);
  const sesion = useSelector((state: RootState) => state.sesion.data);
  const navigate = useNavigate()
  const level = 1

  const viewAll = () => {
    navigate('0/ALL')
  }

  const viewValidate = (level,id) => {

    console.log('level',level,'id',id)

    return (sesion[0][`NIVEL_${ level }`]== null || sesion[0][`NIVEL_${ level }`] == id)

  }

  return (
    <div className="tree-container"> {/* Agrega la clase 'tree-container' */}

      {sesion[0]['NIVEL_1'] == null
        && <Button
          fullWidth
          onClick={() => viewAll()}
        >VER TODO</Button>
      }

      {rows.map((item) => (
        viewValidate(level, item.PLCC_CODIGO)
        && <TreeNode viewValidate={viewValidate} centros={item} level={level} key={item.PLCC_CODIGO} />
      ))}

    </div>
  );
};

export default Tree;
// {sesion[0]['NIVEL_'+level] == (centros.CECO_CODIGO || centros.PLCC_CODIGO) ? <TreeNode centros={item} level={level + 1} /> : <></> }
//   const sesion = useSelector((state: RootState) => state.sesion.data);