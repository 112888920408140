import React from 'react';
import { Routes, Route, Outlet, Link } from 'react-router-dom';
import Navbar from '../navbar-admin';
import HomeIcon from '@mui/icons-material/Home';
import Avatar from '@mui/material/Avatar';
import { useSelector } from 'react-redux';
import { RootState } from 'store'; // Importa el tipo RootState desde el archivo store/index.ts
import LoginMS from '../../component/login-microsoft';

//componentes importados
import Presupuesto from '../Form/presupuesto-form';
import Tree from '../treeNode/Tree';

const menuOptions = [
  { text: 'Presupuesto', icon: <HomeIcon />, path: '/presupuesto' },
  // { text: 'Contabilidad', icon: <HomeIcon />, path: '/contabilidad' },
  // { text: 'Usuarios', icon: <PeopleIcon />, path: '/users' },
  // { text: 'Configuración', icon: <SettingsIcon />, path: '/settings' },
];

const PrivateRoute: React.FC = () => {
  const sesion = useSelector((state: RootState) => state.sesion.data);

  console.log('sesion',sesion)

  const allowedParams = [{
    'UNIDAD_PADRE_CODIGO': sesion[0]['Nivel_1'],
    'PLCC_CODIGO': sesion[0]['Nivel_2'],
    'CECO_CODIGO': sesion[0]['Nivel_3'],
  }];

  const handleLogin = () => {
    // Implementa la lógica de inicio de sesión aquí
    alert('prueba')
  };

  const validateParam = (param: string, value: string) => {
    return allowedParams[param] === value;
  };

  const ParamRoute = ({ path, ...props }: { path: string }) => {
    const params = path.split('/').slice(1); // Obtén los parámetros de la ruta

    // Valida si todos los parámetros están permitidos
    const isParamValid = params.every((param, index) =>
      validateParam(param, sesion[0][`NIVEL_${index + 1}`])
    );

    if (isParamValid) {
      return <Route {...props} />;
    } else {
      // Si algún parámetro no es válido, redirige al usuario a una página de error
      return <Outlet/>;
    }
  };

  return sesion.length > 0 ? (
    <>
      <Navbar
        title="Mi Panel de Administración"
        menuItems={menuOptions}
        loginButton={<Avatar onClick={handleLogin} />}
        childNode={<Tree />}
      />
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route path="/:id/:parametro" element={<Presupuesto />} />
          {/* Agrega más rutas privadas aquí */}
        </Route>
      </Routes>
    </>
  )
    :
    (<LoginMS message={'No tiene acceso a este recurso'} />)
};

export default PrivateRoute;
