import React, { createContext, useContext, useEffect, useState } from 'react';
import { PublicClientApplication, AuthenticationResult, RedirectRequest } from '@azure/msal-browser';
import { useNavigate } from 'react-router-dom';
import { msalConfig } from './authConfig';
import { fetchData } from 'store/sesion/sliceSesion';
import { useDispatch } from 'react-redux';
import LoginMS from '../component/login-microsoft';

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}

interface AuthContextProps {
  isAuthenticated: boolean;
  login: () => void;
  logout: () => void;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const dispatch = useDispatch()
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authInstance, setAuthInstance] = useState<PublicClientApplication | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    const msalInstance = new PublicClientApplication(msalConfig);
    setAuthInstance(msalInstance);

    const accounts = msalInstance.getAllAccounts();
    setIsAuthenticated(accounts.length > 0);
  }, []);

  const handleLogin = async () => {
    if (authInstance) {
      try {
        await authInstance.loginRedirect(generateRedirectRequest());
      } catch (error) {
        console.error('Error al redirigir al inicio de sesión:', error);
      }
    }
  };

  const handleLogout = () => {
    if (authInstance) {
      authInstance.logout();
      setIsAuthenticated(false);
      navigate('/');
    }
  };

  const handleAuthResult = (authResult: AuthenticationResult) => {
    setIsAuthenticated(true);
    console.log('Access token:', authResult.accessToken);
    // Puedes utilizar el token de acceso para realizar llamadas a API protegidas
    navigate('/dashboard'); // Redirige al usuario a la ruta deseada después del inicio de sesión
  };

  const generateRedirectRequest = (): RedirectRequest => {
    const currentUrl = new URL(window.location.href);
    return {
      redirectUri: currentUrl.origin,
      scopes: [], // Agrega los scopes necesarios aquí
    };
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login: handleLogin, logout: handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuth debe ser utilizado dentro de un AuthProvider');
  }
  return authContext;
};
