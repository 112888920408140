import { configureStore } from '@reduxjs/toolkit';
import centroCosto from './centroCosto/sliceCentro';
import centroCostoDetalle from './centroCostoDetalle/sliceCentroCostoDetalle';
import estadosFinancieros from './contabilidad/sliceContabilidad';
import estadosFinancierosDetalle from './contabilidadDetalle/sliceContabilidadDetalle';
import sesion from './sesion/sliceSesion';

// Importa tus reducers y cualquier middleware que desees utilizar

const store = configureStore({
  reducer: {
    sesion: sesion.reducer,
    centroCosto: centroCosto.reducer,
    centroCostoDetalle: centroCostoDetalle.reducer,
    estadosFinancieros: estadosFinancieros.reducer,
    estadosFinancierosDetalle:estadosFinancierosDetalle.reducer
  },
  middleware: (getDefaultMiddleware) => {
    // Agrega cualquier middleware personalizado aquí
    return getDefaultMiddleware();
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
