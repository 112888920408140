import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { PLCCData } from '@/interfaces/inter-ccosto';

export interface ApiResponse {
  data: PLCCData[];
  status: string;
}

export interface SliceState {
  data: PLCCData[] | []; // Asegúrate de que data pueda ser nulo inicialmente
  loading: boolean;
  error: string | null;
}

const initialState: SliceState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchData = createAsyncThunk('ccosto/fetchData', async (_, thunkAPI) => {
  try {
    const token = process.env.REACT_APP_TOKEN; // Reemplaza con tu token de autenticación
    const response = await axios.get('https://ws.ugm.cl/api/v1_presupuesto/ccosto', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
   // console.log('response.data', response.data);
    return response.data;
  } catch (error) {
    throw new Error('Error al cargar los datos');
  }
});

const ccostoSlice = createSlice({
  name: 'centroCosto',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchData.fulfilled, (state, action: PayloadAction<PLCCData[]>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchData.rejected, (state, action: PayloadAction<any, string, any, any>) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default ccostoSlice;
