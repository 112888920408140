import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../msal/authProvider';
import { useIsAuthenticated } from '@azure/msal-react';

const MicrosoftLoginButton: React.FC = () => {

    const navigate = useNavigate();

    const isAuthenticated = useIsAuthenticated(); // Almacenar el valor de autenticación

    const { login, logout } = useAuth();

    const handleClick = () => {
        isAuthenticated ? logout() : login(); // Utilizar la variable isAuthenticated en la condición
    };




    return (
        <Button
            onClick={handleClick}
            variant="contained"
            style={{
                backgroundColor: 'black',
                color: 'white',
                fontWeight: 'bold',
                padding: '10px 20px',
                borderRadius: '4px',
                boxShadow: 'none',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '400px',
                margin: '0 auto'
            }}
        >
            <img
                src="/img/ms-button.png"
                alt="Microsoft Logo"
                style={{ width: '24px', height: '24px', marginRight: '8px' }}
            />
            {isAuthenticated ? 'Cerrar sesión de cuenta Microsoft' : 'Iniciar sesión con cuenta Microsoft'}
        </Button>
    );
};

export default MicrosoftLoginButton;

