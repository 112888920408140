import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import MicrosoftButton from '../msal/ms-button'
import { useIsAuthenticated } from '@azure/msal-react';


const LogInCard: React.FC<{ message?: string }> = ({ message }) => {

  const isAuthenticated = useIsAuthenticated(); // Almacenar el valor de autenticación

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Card>
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
            <img
              src="/img/Logo_ugm.png"
              alt="Logo de la empresa"
              style={{ width: '150px', height: '150px' }}
            />
          </div>

          <Typography sx={{ textAlign: 'center',color:'red' }} variant="h5" component="h2" gutterBottom>
            {message || ''}
          </Typography>
          <Typography sx={{ textAlign: 'center' }} variant="h5" component="h2" gutterBottom>
            {isAuthenticated ? 'Cerrar Sesión' : 'Iniciar Sesión'}
          </Typography>
          <MicrosoftButton />
        </CardContent>
      </Card>
    </div>
  );
};

export default LogInCard;
