import { Centro, PLCCData } from "@/interfaces/inter-ccosto";
import { RootState } from "@/store";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

type TreeNodeProps = {
  centros: (Centro & PLCCData) | any;
  level: number;
  viewValidate: Function;
};

const TreeNode: React.FC<TreeNodeProps> = ({ centros, level, viewValidate }) => {
  const sesion = useSelector((state: RootState) => state.sesion.data);
  const [isExpanded, setIsExpanded] = useState(true);
  const navigate = useNavigate();

  const toggleExpanded = (parameter: string, nameParameter: string) => {
    // setIsExpanded(!isExpanded);
    viewValidate(level +1 , parameter) && navigate(`${parameter}/${nameParameter}`);
  };

  const getParameterNode = () => {
    switch (level) {
      case 1:
        return 'UNIDAD_PADRE_CODIGO';
      case 2:
        return 'PLCC_CODIGO';
      case 3:
        return 'CECO_CODIGO';
    }
  };

  const centrosDependientes = 'DEPENDIENTES' in centros ? centros.DEPENDIENTES : [];

  if (centrosDependientes.length > 0) {
    return (
      <div className="folder">
        <h2
          className={`folder-title level-${level}`}
          onClick={() => toggleExpanded(centros.CECO_CODIGO || centros.PLCC_CODIGO, getParameterNode())}
        >
          {centros.PLCC_NOMBRE || centros.CECO_NOMBRE}
        </h2>
        {isExpanded && (
          <ul className="file-list">
            {centrosDependientes.map((item) => (
              <li key={item.PLCC_CODIGO || item.CECO_CODIGO}>
                {
                  viewValidate(level +1 , (item.CECO_CODIGO || item.PLCC_CODIGO ))
                  && <TreeNode key={item.PLCC_CODIGO} viewValidate={viewValidate} centros={item} level={level + 1} />}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }

  return null;
};

export default TreeNode;
